import { useAuthStore } from '~/store/auth'
import { cookieKeyName } from '~/libs/consts'

export default defineNuxtRouteMiddleware((to, from) => {
  const router = useRouter()
  const auth = useAuthStore()

  if (process.server)
  {
    // background
    const authCookie = useCookie(cookieKeyName)
    auth.token = authCookie.value || undefined
    auth.checkAuth = true
  }
  else
  {
    // foreground
    if (!auth.checkAuth)
    {
      location.href = '/manager/'
    }
  }

  if (auth.token)
  {
    switch (to.name)
    {
      case 'manager-login':
        router.replace('/manager/')
        break
    }
    return true
  }
  else
  {
    switch (to.name)
    {
      case 'manager-login':
        return true
      default:
        router.replace('/manager/login/')
        break
    }
  }
})
